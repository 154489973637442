<template>
  <div class="body fixed mobile">
    <section class="header store_register">
      <div class="flexH width">
        <a
          class="icon iconButton third"
          @click.prevent="$router.push('/setting')"
        >
          <font-awesome-icon icon="fa-solid fa-chevron-left" size="lg" />
        </a>
      </div>
      <span class="fL nowrap">{{ title }}</span>
      <div class="flexH width right">
        <!-- <a class="textButton fM third">草稿暫存</a> -->
      </div>
    </section>
    <div class="main bg-white">
      <iframe :src="iframeUrl" class="iframe"></iframe>
    </div>
  </div>
</template>

<script>
export default {
  name: "Static",
  computed: {
    title() {
      return this.$route.query.type === 'terms' ? '服務條款與隱私條款' : '關於雲林幣扭一下2.0';
    },
    iframeUrl() {
      return this.$route.query.type === 'terms' ? 'https://yunlincoin.yunlin.gov.tw/Polices/Privacy' : 'https://planning.yunlin.gov.tw/News.aspx?n=11486&sms=16781';
    }
  }
};
</script>
<style scoped>
.iframe {
  width: 100%;
  height: calc(100vh - 150px);
}
</style>