<template>
  <div class="body fixed mobile">
    <section class="header profile_edit">
      <div class="flexH width">
        <a
          class="icon iconButton third"
          @click.prevent="$router.push('/profile')"
        >
          <font-awesome-icon
            icon="fa-solid fa-chevron-left"
            size="lg"
          ></font-awesome-icon>
        </a>
      </div>
      <span class="fL nowrap">雲林幣規則</span>
      <div class="flexH width right"></div>
    </section>
    <main class="main">
      <h4>
        雲林幣是由雲林縣政府發行的數位虛擬代幣，民眾可透過下載「雲林幣扭一下」APP閱讀政令、參與活動及問卷或其他不定期活動來獲得雲林幣。
      </h4>

      <p>
        每 10 枚雲林幣等值 1
        元新臺幣，民眾可於APP中尋找特約店家，並前往兌換等值商品或服務。
      </p>

      雲林幣有使用期限，到期日為每年9月30日，提醒您在期限前速兌換完畢。
      <br /><br />
      雲林幣發放通常會有人數限制，別忘了開APP推播通知。
      <br /><br />
      為保障雲林幣扭一下APP公平性，每人所擁有之帳號務必確認其有效性，官方自112年起將不定時檢查帳號，如經查詢不實或手機號碼停用/空號，官方有權更改使用者權益或停用帳號。
      <br /><br />
      雲林幣扭一下APP保留隨時修改、變更、暫停或終止不定期任務活動內容之最終權利，並以APP內公告為準，恕不另行通知。若有其他未盡事宜，悉依本府相關規定或解釋。
      <br /><br />
    </main>
  </div>
</template>

<script>
export default {
  name: "CoinRule",
};
</script>
<style  scoped>
p {
  margin: 1.33em 0;
}
</style>
